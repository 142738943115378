.category-card {
  box-sizing: border-box;
  width:300px;
  height:200px;
  background: rgba(253, 253, 253, 0.733);
  backdrop-filter: blur(6px);
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
  border: 2px solid black;
  border-radius: 17px;
  padding:10px;
 box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.3);;
}

.category-card .title{
  /* width:250px; */
  background:none;
  display:grid;
  grid-template-columns: 4fr 8fr;
  
}
/* .category-card .details{
  background:none;
  display:flex;
  flex-flow: row;
} */

.category-card .title p{
  padding:10px;
  width:130px;
  text-wrap: nowrap;
  text-align: center;
  text-justify: auto;
  font-size: large;
 font-weight:bolder;
 font-family: 'Courier New', Courier, monospace;
}

.category-card  img{
  width:5rem;
  height:5rem;
  object-fit: contain;
  background: none;
  border-radius:50%;
}

.category-card:hover {
  border: 1px solid rgb(17, 58, 240);
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.651);
  transform: scale(1.05);
}

.category-card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}