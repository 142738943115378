/* Home.css or your stylesheet */
.section{
  margin-top: 100px;
}

/* header{
  top:0;
  background:rgba(255, 255, 255, 0.5);
    color:rgb(8, 6, 6);
    width: 100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 20px 0px 20px 0px; 
    position:fixed;
    height: 100px;
    z-index: 99999;
    -webkit-backdrop-filter:saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom:1px solid rgba(255, 255, 255, 0.5);
} */

/* nav{
    color:white;
    width:600px;
    margin-right:50px;
    display:inline-flex ;
    justify-content:space-between;
    align-items:center;

  } */
  .a{
    text-shadow:#000000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:700;
    font-size: large;
  }

  
  .a:hover{
    color:rgb(236, 74, 74);
}

/* .logo{
    margin-left:100px;
    background-color:rgb(255, 255, 255);
    mix-blend-mode:multiply;
    filter: drop-shadow(0 0 30px #ffffff);
    height:50px;
    width:50px;    
    
} */


  .image-container {
    position: relative;
    width: 100%;
}

.image-container img {
    width: 100%;
    height: 400px;
    background:none;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center;
    background-attachment: fixed;

}

/* Hero */
.hero{
  width: 100%;
  height:800px;
  background-image: url("../../images/jobsearchi_123.jpg");
  object-fit: contain;
  display:flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-evenly;
}

.hero h1{
  color:#ffffff;
  display:flex;
  column-gap: 0px;
  padding-left:30px;
  top:50px;

}

.hero .hero-text {
  background-color:"whitesmoke";
  background: none;
  font-weight:bold;
   font-family:'Times New Roman', Times, serif;
   font-size:30px;
   font-weight: bold;
   text-shadow: 2px 2px 4px #000000;
   color:rgb(255, 255, 255);
}

.text-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color and opacity as needed */
    padding: 20px; /* Adjust the padding as needed */
}

.text-box p {
    margin: 0;
}

/* cards */

/* .card:hover{
  transform: scale(1);
  transition: ease;
  box-shadow: 10 4px 18px 0 rgba(0, 0, 0, );
 } */

 
 .text-.btn-lg {
  color:whitesmoke;
  height: 10px;
 }
 
 /*Text*/
 .text-title {
font-size: 50px;
 }
 
 /*Hover*/
 
 /* categories  */
 .section-2 .single_catagory {
  background: #c9cbf3 ;
  padding: 25px;
  margin-bottom: 20px;
}
.section-2 .single_catagory h4 {
  font-size: 18px;
}
.white-bg {
  background: #ffffff;
}


.section-3 .sidebar h2 {
  font-family: 'Inter-Medium';
  font-size: 18px;
  padding-bottom: 15px;
  font-weight: normal;
  text-transform: none;
}
.section-3 .sidebar h2:after {
  display: none;
}
.section-3 .sidebar .form-check-label {
  font-size: 18px;
}
.section-3 .job_listing_area .job_lists .single_jobs {
  padding: 30px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_left .thumb {
  float: left;
  width: 82px;
  height: 82px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
  background: #F5F7FA;
  margin-right: 25px;
  border: 1px solid #F0F0F0;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent {
  float: left;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location {
  margin-right: 50px;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
  margin-bottom: 0;
  font-size: 16px;
  color: #AAB1B7;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4 {
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: 'Inter-Medium';
  text-transform: none;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.heart_mark {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #0ed300;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #EFFDF5;
  margin-right: 15px;
}
.section-3 .job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.heart_mark:hover {
  background: #00D363;
  color: #fff;
}

 
.job-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.jobLogo{
  display:flex;
  width: 20px;
  height: 20px;
}
.jobLogo p{
  width:100px;
  height:30px;
}

.job-card {
  border: 1px solid #ccc;
  padding: 10px;
  width: 200px;
}

/* login in styling */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
  height: 100%;
}
}

/* profile card */

.profileContainer{
  width: 600px;
  height: 600px;
  background-color: black;
  justify-self: center;
}
/* jobsection */


.jobsections{
  background:#ffffff;
  margin:20px;
  width:100%;
  height:max-content;
  display:flex;
  justify-content: center;
  align-items:center;
  flex-wrap:wrap;
  gap:20px;
}

.jobsections .category-text{
  font-weight:bold;
  color: #1D46F5;
  font-family:'Courier New', Courier, monospace;
  width:100%;
   display:flex;
    height:100px;
    justify-content:center;
    align-items:center;
     font-size:50px; 
     
}

/* 
@media screen and (max-width: 767px) {

}


@media and (min-width: 768px) and (max-width:1023){
  

}


@media screen and (min-width: 1024) {
  
} */